<template>
  <CentralizeLayout>
    <b-col md="4">
      <div>
        {{ $t("header") }}
      </div>

      <b-form class="mt-5" novalidate @submit.prevent="onSubmit">
        <b-alert class="mb-3" :show="hasProfileStatus" :variant="'danger'">{{
          $t("errorDesc.unspecific")
        }}</b-alert>
        <b-form-group :label="$t('username')">
          <b-form-input
            v-model="form.username"
            type="text"
            required
            :state="validateState('username')"
            :disabled="isLoading"
            @blur="$v.form.username.$touch()"
          />
          <b-form-invalid-feedback>{{
            $t("usernameErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mt-3" :label="$t('teamName')">
          <b-form-input
            v-model.trim.lazy="$v.form.teamName.$model"
            type="text"
            required
            :state="validateState('teamName')"
            :disabled="isLoading"
            @blur="$v.form.teamName.$touch()"
          />
          <b-form-invalid-feedback>{{
            $t("teamNameErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mt-3" :label="$t('workspaceName')">
          <b-form-input
            v-model.trim.lazy="$v.form.workspaceName.$model"
            type="text"
            required
            :state="validateState('workspaceName')"
            :disabled="isLoading"
            @blur="$v.form.workspaceName.$touch()"
          />
          <b-form-invalid-feedback>{{
            $t("workspaceNameErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>

        <b-button
          class="mt-5"
          block
          :disabled="isLoading"
          type="submit"
          variant="primary"
        >
          <b-icon-arrow-clockwise v-show="isLoading" animation="spin" />
          {{ $t("confirm") }}
        </b-button>
      </b-form>
    </b-col>
  </CentralizeLayout>
</template>

<i18n>
{
  "en": {
    "header": "Before you start, we would like to guide you to set up your own workspace.",
    "username": "Username",
    "usernameErrMsg": "Enter your username.",
    "teamName": "Team name",
    "teamNameErrMsg": "Enter your team name.",
    "workspaceName": "First workspace name",
    "workspaceNameErrMsg": "Enter your first workspace name.",
    "confirm": "Confirm",
    "errorDesc": {
      "unspecific": "Failed to create your first workspace."
    }
  },
  "id": {
    "header": "Sebelum anda mulai, kami ingin memandu anda untuk menyiapkan ruang kerja anda sendiri.",
    "username": "Nama pengguna",
    "usernameErrMsg": "Masukkan nama pengguna anda.",
    "teamName": "Nama tim",
    "teamNameErrMsg": "Masukkan nama tim anda.",
    "workspaceName": "Nama ruang kerja pertama",
    "workspaceNameErrMsg": "Masukkan nama ruang kerja pertama anda.",
    "confirm": "Konfirmasi",
    "errorDesc": {
      "unspecific": "Gagal membuat ruang kerja pertama anda."
    }
  }
}
</i18n>

<script>
import { required } from "vuelidate/lib/validators";
import CentralizeLayout from "@/layouts/CentralizeLayout";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CentralizeLayout
  },
  data() {
    return {
      isLoading: false,
      form: {
        username: "",
        teamName: "My team",
        workspaceName: "Personal"
      }
    };
  },
  computed: {
    ...mapGetters("profile", ["hasProfiled", "hasProfileStatus"]),
    ...mapGetters("auth", ["authenticatedUser"])
  },
  validations: {
    form: {
      username: {
        required
      },
      teamName: {
        required
      },
      workspaceName: {
        required
      }
    }
  },
  mounted() {
    this.clearProfileStatus();
  },
  methods: {
    ...mapActions("profile", ["createProfile", "clearProfileStatus"]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error ? false : null;
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      await this.createProfile({
        username: this.form.username,
        teamName: this.form.teamName,
        workspaceName: this.form.workspaceName,
        email: this.authenticatedUser.attributes.email
      });
      if (this.hasProfiled) {
        this.$router.push({ name: "Workspace" });
      }
      this.isLoading = false;
    }
  }
};
</script>
